<template src="./emptyRecommendation.html"></template>

<script>
// import axios from 'axios';
import productCard from '../productCard/productCard';
import header from '../header/header';
export default {
  name: 'emptyRecommendation',
  components: {
    productCard,
    recommendationHeader: header,
  },
  data() {
    return {
      quotes: null,
    };
  },
  computed: {},
  created() {
    /*axios
      .get('https://soco-loyalty-feature-api.sociolabs.io/beauty-quotes?limit=1&sort=-created_at')
      .then(res => {
        const data = res.data.data;
        // console.log(data);
        this.quotes = data;
      });*/
  },
};
</script>

<style lang="scss" scoped>
@import 'emptyRecommendation';
</style>
